import moment, { type Moment } from "moment";
import { InjectionKey } from "vue";
import type { Currency } from "~/helpers/apollo/types";
import { arrayLast, objectFromKeys, TranslationKey } from "~/helpers/common-helpers";
import { capitalizeAll, dateRange } from "~/helpers/filter-helpers";
import { NamespacePortfolioOpex } from "~/helpers/user-preferences-helpers";

export const getPeriodLabel = (columns: Moment[], periodRangeType: NamespacePortfolioOpex["periodRangeType"]) => {
  const periodFrom = columns[0];
  const periodTo = arrayLast(columns);
  if (!periodFrom || !periodTo) return "";

  switch (periodRangeType) {
    case "yearRolling":
      return capitalizeAll(
        `${dateRange(moment.utc(periodFrom).startOf("month").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("month").format("YYYY-MM-DD"))}`
      );
    case "yearToDate":
      return capitalizeAll(
        `${dateRange(moment.utc(periodFrom).startOf("month").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("month").format("YYYY-MM-DD"))}`
      );
    case "yearWhole":
      return `${dateRange(moment.utc(periodFrom).startOf("year").format("YYYY-MM-DD"), moment.utc(periodTo).endOf("year").format("YYYY-MM-DD"))}`;
  }
};

export type TableOpexesPropertyData = {
  resultNormal: number;
  resultTotal: number;
  budgetNormal: number;
  budgetTotal: number;
  area: number;
  areaUnit: Nullable<string>;
  tenancies: number;
};

export type PortfolioPropertyOpexCategoryData = {
  id: string;
  name: string;
  currency: Currency;

  current: {
    average: TableOpexesPropertyData;
    propertiesData: Map<string, TableOpexesPropertyData>;
  };

  reference?: {
    average: TableOpexesPropertyData;
    propertiesData: Map<string, TableOpexesPropertyData>;
  };
};

export const OpexSettingsInjectionKey = Symbol("opexSettingsInjectionKey") as InjectionKey<{ preferencesClone: NamespacePortfolioOpex }>;

export const periodRangeOptions = [
  { id: "yearWhole", name: "PORTFOLIO_VIEW_OPEX_PERIOD_WHOLE_YEAR" },
  { id: "yearRolling", name: "PORTFOLIO_VIEW_OPEX_PERIOD_LATEST_12" },
  { id: "yearToDate", name: "PORTFOLIO_VIEW_OPEX_PERIOD_YEAR_TO_DATE" },
] as { id: NamespacePortfolioOpex["periodRangeType"]; name: TranslationKey }[];

export type OpexSettings = Omit<NamespacePortfolioOpex, "template" | "templateModified" | "savedTemplates">;

export type OpexSettingsTemplate = {
  id: string;
  name: string;
  settings: OpexSettings;
};

export const opexSavedSettingsFields = [
  "benchmark",
  "excludePropertyIds",
  "includeZeroRows",
  "normaliseBy",
  "periodOffset",
  "periodRangeType",
  "referenceMonthOffset",
  "referencePercentage",
] as const;

export const settingsTemplateOptions = [
  {
    id: "normLatest12",
    name: "normLatest12",
    settings: {
      benchmark: "average.total",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearRolling",
      referenceMonthOffset: undefined,
      referencePercentage: false,
    },
  },

  {
    id: "normDevelopment12Percentage",
    name: "normDevelopment12Percentage",
    settings: {
      benchmark: "average.total",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearRolling",
      referenceMonthOffset: -12,
      referencePercentage: true,
    },
  },

  {
    id: "budgetYTD",
    name: "budgetYTD",
    settings: {
      benchmark: "budget.diff",
      excludePropertyIds: ["companies"],
      includeZeroRows: false,
      normaliseBy: "area",
      periodOffset: 0,
      periodRangeType: "yearToDate",
      referenceMonthOffset: undefined,
      referencePercentage: false,
    },
  },
] as const satisfies OpexSettingsTemplate[];

export type StandardOpexTemplateId = (typeof settingsTemplateOptions)[number]["id"];

export const OPEX_TEMPLATE_DEFAULT = "normLatest12" as const satisfies StandardOpexTemplateId;

export const getSelectedTemplate = (preferences: NamespacePortfolioOpex | null) =>
  preferences?.savedTemplates.find((template) => template.id === preferences.template) ??
  settingsTemplateOptions.find((template) => template.id === preferences?.template);

export const settingsTemplateOptionsTranslationKeys = {
  budgetYTD: "PORTFOLIO_OPEX_TEMPLATE_BUDGET_YTD",
  normLatest12: "PORTFOLIO_OPEX_TEMPLATE_NORM_ROLLING",
  normDevelopment12Percentage: "PORTFOLIO_OPEX_TEMPLATE_NORM_DEVELOPMENT_12_PERCENTAGE",
} as const satisfies { [k in StandardOpexTemplateId]: TranslationKey };

export const sanitizeOpexSettings = (preferences: OpexSettings | NamespacePortfolioOpex): OpexSettings =>
  objectFromKeys(preferences, ...opexSavedSettingsFields);
