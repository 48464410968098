import { render, staticRenderFns } from "./ViewOpex.vue?vue&type=template&id=5e589040"
import script from "./ViewOpex.vue?vue&type=script&setup=true&lang=ts"
export * from "./ViewOpex.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ViewOpex.vue?vue&type=style&index=0&id=5e589040&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetLayoutRowSpacer: require('/app/components/Widget/WidgetLayoutRowSpacer.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default})
