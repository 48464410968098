import { render, staticRenderFns } from "./ViewProperties.vue?vue&type=template&id=a6f53630"
import script from "./ViewProperties.vue?vue&type=script&setup=true&lang=ts"
export * from "./ViewProperties.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompositionSpacer: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,TickBox: require('/app/node_modules/@align/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default})
