import { render, staticRenderFns } from "./ViewNormAndPeriod.vue?vue&type=template&id=996b363a"
import script from "./ViewNormAndPeriod.vue?vue&type=script&setup=true&lang=ts"
export * from "./ViewNormAndPeriod.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default})
